<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'
import china from "./mixins/china.json"

export default {
    mixins: [resize],
    props: {
        mapData: {
            type: Array,
            default: function () {
                return []
            }
        },
        mapName: {
            type: String,
            default: 'chart'
        },
        visualMax: {
            type: Number,
            default: 500
        },
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            let self = this
            this.chart = echarts.init(document.getElementById(this.id))
            echarts.registerMap('CHINA', china)
            this.chart.setOption({
                tooltip: {
                    trigger: "item",
                    showDelay: 0,
                    transitionDuration: 0.2,
                    formatter: function (params, ticket, callback) {
                        const value = params.data ? params.data.value : '-'
                        return params.name + '<br/>'+ self.mapName +'：' + value;
                    }
                },
                visualMap: {
                    type: "piecewise",
                    max: this.visualMax,
                    splitNumber: 4,
                    text: ["高","低"],
                    realtime: false,
                    calculable: true,
                    orient: 'horizontal',
                    inRange: {
                        color: [ "#4cb5ff", "#ff8533", "#f3d024","#2062e6"]
                    }
                },
                series: [{
                    type: 'map',
                    map: 'CHINA',
                    data: this.mapData
                }]
            })
        }
    }
}
</script>
